<template>
    <div>
        <div style="display: flex;justify-content: space-between">
            <div>
                <el-button type="primary" @click="addBtn" size="mini">添加标签</el-button>
            </div>
        </div>
        <br>
        <el-table  :data="tagList"   border  style="width: 100%">
            <el-table-column   align="center"    prop="tag_name"    label="标签名称"></el-table-column>
            <el-table-column  align="center"   prop="tag_type"  label="类型">
                <template slot-scope="scope">
                    {{scope.row.tag_type==0?'单选':'多选'}}
                </template>
            </el-table-column>
            <el-table-column label="添加时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						    {{scope.row.add_time | formatTime}}
                         </div>
                      </template>
            </el-table-column>
            <el-table-column label="更新时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						    {{scope.row.update_time | formatTime}}
                         </div>
                      </template>
            </el-table-column>
            <el-table-column  label="操作"  fixed="right"  align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="lookSon(scope.row)">查看子标签</el-button>
                    <el-button @click="update(scope.row)" type="text" size="small">更新</el-button>
                    <el-button type="text" size="small" @click="del(scope)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
                :title="tag_info.tag_name+'的子标签'"
                :visible.sync="show_son_tag"
                width="700px"
        >
            <el-tag v-for="tag in tag_info.tag_son_name" :key="tag" >
                {{tag}}
            </el-tag>
        </el-dialog>
        <el-dialog
                :title="title"
                :visible.sync="show_editor_tag"
                width="700px"
        >
            <el-form  :model="tag_info" label-width="120px">
                <el-form-item label="标签名称">
                    <el-input v-model="tag_info.tag_name" style="width: 300px"></el-input>
                </el-form-item>
                <el-form-item label="子标签名称">
                    <el-tag
                            style="margin-right: 5px"
                            :key="tag"
                            v-for="tag in tag_info.tag_son_name"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(tag)">
                        {{tag}}
                    </el-tag>
                    <el-input
                            class="input-new-tag"
                            v-if="inputVisible"
                            v-model="inputValue"
                            ref="saveTagInput"
                            size="mini"
                            @keyup.enter.native="handleInputConfirm"
                            @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">添加标签</el-button>
                </el-form-item>
                <el-form-item label="标签类型">
                    <el-radio-group v-model="tag_info.tag_type">
                        <el-radio label="0">单选</el-radio>
                        <el-radio label="1">多选</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="show_editor_tag = false">取 消</el-button>
                    <el-button type="primary" @click="addTag">确 定</el-button>
                </span>
        </el-dialog>

    </div>
</template>
<script>
    export default {
        data(){
            return {
                show_son_tag:false,
                tagList:[],
                title:'添加标签',
                show_editor_tag:false,
                tag_info:{
                    id:'',
                    tag_name:'',
                    tag_son_name:[],
                    tag_type:'0',
                    tag_str:''
                },
                inputVisible: false,
                inputValue: '',
            }
        },
        mounted(){
            document.title = '会员标签'
            this.getTagList()
        },
        methods:{
            lookSon(row){
                this.show_son_tag = true
                this.tag_info = row
            },
            getTagList(){
                let _this=this
                this.$post('/pc/member/getMemberTag',{},function (res) {
                        let result = res.data
                      if(result.code==200){
                          for(let i in result.data){
                              if(!result.data[i].tag_son_name){
                                  result.data[i].tag_son_name=[]
                              }
                          }
                          _this.tagList=result.data
                          _this.$message.success(result.msg)
                      }
                      if(result.code==401){
                         _this.$router.push({path:'/'})
                      }
                })
            },
            handleClose(tag) {
                this.tag_info.tag_son_name.splice(this.tag_info.tag_son_name.indexOf(tag), 1);
            },
            showInput() {
                this.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
				if(this.tag_info.tag_son_name.length>=10){
					return this.$message.error('最多添加10条子标签')
				}
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.tag_info.tag_son_name.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            addTag(){
                if(this.tag_info.tag_son_name.length==0) return this.$message.error('请添加子标签后在保存')
                let _this=this
                let tag_str = ''

                if(this.tagList.length>=20) return this.$message.error('最多添加20条')

                for(let i in _this.tag_info.tag_son_name){
                    tag_str = tag_str + _this.tag_info.tag_son_name[i]+','
                }
                _this.tag_info.tag_str=tag_str

                this.$post('/pc/member/addMemberTag',_this.tag_info,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.show_editor_tag=false
                        _this.$message.success(result.msg)
                        _this.getTagList()
                    }
                })
            },
            update(row){
                this.title='更新标签'
                this.show_editor_tag=true
                console.log(row)
                this.tag_info=row
            },
            del(el){
                let _this=this

                if(confirm('确定删除')){

                    var index = el.$index
                    var id=el.row.id
                    this.$post('/pc/member/delMemberTag',{id:id},function (res) {
                        let result = res.data
                        if(result.code==200){
                            _this.tagList.splice(index,1)
                           _this.$message.success(result.msg)
                        }
                    })
                }
            },
            addBtn(){
                this.show_editor_tag=true
                this.title='添加标签'
                this.tag_info={
                    id:'',
                    tag_name:'',
                    tag_son_name:[],
                    tag_type:'0',
                    tag_str:''
                }
            },
        }
    }
</script>
<style>
	
</style>
